import React, { useEffect, useState } from 'react';
import { Typography, Box, Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import api from './components/api';
import { theme } from './components/styles';
import NavigationBar from './NavigationBar';
import ContactForm from './ContactForm';
import Footer from './Footer';

interface ArticleData {
  title: string;
  date: string;
  slug: string;
  content: string;
}

const ArticleDetail: React.FC = () => {
  const { t, i18n } = useTranslation();

  const pageTitle = t('News - Microscope for Lab Automation｜embrio');
  const pageDescription = t('We support bio-research with a microscope for lab automation.\nThrough simple API control, robot integration, and seamless data management, we accommodate any protocol for you.');
  const siteUrl = 'https://www.embrio.dev/news:slug';

  const { slug } = useParams<{ slug: string }>();
  const [article, setArticle] = useState<ArticleData | null>(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await api.get(`/api/news/${slug}?lang=${i18n.language}`);
        setArticle(response.data);
      } catch (error) {
        console.error("Fetching article failed", error);
      }
    };

    fetchArticle();
  }, [slug, i18n.language]);

  if (!article) return <div>Loading...</div>;

  return (
    <ThemeProvider theme={theme}>

      <Helmet>
        <link rel="canonical" href="https://www.embrio.dev/" />

        {/* Basic SEO */}
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="icon" href="/images/embrio_logo_square.png" />
        {/* <link rel="icon" type="image/png" href="./images/embrio_logo_square.png" sizes="16x16" /> */}

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {/* <meta property="og:image" content={imageUrl} /> */}

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={siteUrl} />
        <meta property="twitter:title" content={pageTitle} />
        <meta property="twitter:description" content={pageDescription} />
        {/* <meta property="twitter:image" content={imageUrl} /> */}
      </Helmet>

      <NavigationBar />

      <Container maxWidth="md">
        <Box sx={{ py: 6 }}>
          <Typography variant="h4">{article.title}</Typography>
          <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
            Date: {new Date(article.date).toLocaleDateString()}
          </Typography>
          <Box dangerouslySetInnerHTML={{ __html: article.content }} />
        </Box>
      </Container>

      <ContactForm />
      <Footer />
    </ThemeProvider>
  );
};

export default ArticleDetail;
